import type { Category } from '../../../types/category';
import NavLinkCategory from '../navlink/NavLinkCategory';
import React from 'react';

import './NavBarDesktop.css';
import NavBarDropDown from '../navbardropdown/NavBarDropDown';

type Props = {
  categories: Category[],
  moreCategories?: Category[],
}

export default function NavBarDesktop({ categories, moreCategories }: Props) {
  return (
    <div className='navbardesktop-area'>
      <div className='navbardesktop-horizontalline' />
      <div className='navbardesktop-links'>
        { categories.map(cat => <NavLinkCategory key={cat.value} label={cat.label} category={cat.value}/>) }
        { moreCategories && <NavBarDropDown categories={moreCategories} /> }
      </div>
    </div>
  );
}
