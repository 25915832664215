import type { RouteRenderArgs } from 'found';
import Route from 'found/Route';
import makeRouteConfig from 'found/makeRouteConfig';
import React from 'react';

import App from '../components/app/App';
import ArticlePage, { articlePageQuery } from '../components/pages/ArticlePage';
import HomePage, { homePageQuery } from '../components/pages/HomePage';
import CategoryPage, { categoryPageQuery } from '../components/pages/CategoryPage';
import SearchByTagPage, { searchByTagPageQuery } from '../components/pages/SearchByTagPage';
import SearchByTextPage, { searchByTextPageQuery } from '../components/pages/SearchByTextPage';
import SearchByTickerPage, { searchByTickerPageQuery } from '../components/pages/SearchByTickerPage';
import LoadingArticles from '../components/common/LoadingArticles';

function loadingRender({ Component, props }: RouteRenderArgs) {
  if (!Component || !props) {
    return <LoadingArticles />;
  }

  return <Component {...props} />;
}

export default makeRouteConfig(
  <Route
    path="/"
    Component={App}
  >
    <Route
      Component={HomePage}
      render={loadingRender}
      query={homePageQuery}
    />
    <Route
      path="article/:articleId"
      Component={ArticlePage}
      render={loadingRender}
      query={articlePageQuery}
      prepareVariables={(params: any) => (params)}
    />
    <Route
      path="/category/:category"
      Component={CategoryPage}
      render={loadingRender}
      query={categoryPageQuery}
      prepareVariables={(params: any) => (params)}
    />
    <Route
      path="/tag/:tag"
      Component={SearchByTagPage}
      render={loadingRender}
      query={searchByTagPageQuery}
      prepareVariables={({tag}: any) => ({tag: tag.toLowerCase()})}
    />
    <Route
      path="/ticker/:code"
      Component={SearchByTickerPage}
      render={loadingRender}
      query={searchByTickerPageQuery}
      prepareVariables={(params: any) => (params)}
    />
    <Route
      path="/search/:query"
      Component={SearchByTextPage}
      render={loadingRender}
      query={searchByTextPageQuery}
      prepareVariables={(params: any) => (params)}
    />
    <Route
      path="*"
      Component={HomePage}
      render={loadingRender}
      query={homePageQuery}
    />
  </Route>,
);