import React, { SyntheticEvent, useEffect, useState } from 'react';
import { getCurrentUrlParams } from '../../routing/query_param_helper';
import SearchIcon from '../icons/SearchIcon';
import { useParams, useRouter } from 'found'; 

import './SearchBar.css';


type MatchParams = {
  tag?: string,
  code?: string,
  query?: string,
}

function getQueryStringFromParams({tag, code, query}: MatchParams): string | null {
  if (query != null) {
    return query;
  }
  if (tag != null) {
    return `tag:${tag}`;
  }
  if (code != null) {
    return `ticker:#${code}`;
  }
  return null;
}


function getDestURLPath(queryString: string) {
  if (queryString.startsWith('tag:')) {
    const tag = queryString.substring(4).trim();
    return `/tag/${tag}`;
  }

  if (queryString.startsWith('ticker:')) {
    let ticker = queryString.substring(7).trim();
    if (ticker.startsWith('#')) {
      ticker = ticker.substring(1);
    }
    return `/ticker/${ticker}`;
  }

  return `/search/${queryString}`;
}


export default function SearchBar() {
  const { router } = useRouter();
  const params = useParams();
  const initialQueryString = getQueryStringFromParams(params);
  const [queryString, setQueryString] = useState<string|null>(initialQueryString);

  useEffect(() => {
    setQueryString(getQueryStringFromParams(params));
  }, [params]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (queryString == null || queryString === "") {
      return;
    }

    router.replace({
      pathname: getDestURLPath(queryString),
      query: getCurrentUrlParams(),
    })
    
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="searchBar-area">
        <input
          className="searchBar-input"
          type="search"
          name="query"
          placeholder="Search..."
          value={queryString || ""}
          onChange={(e) => setQueryString(e.target.value)}
          />
        <div className="searchBar-button" onClick={handleSubmit}>
          <SearchIcon width={16} />
        </div>
      </div>
    </form>
  );
}