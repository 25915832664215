import type { Category } from '../../../types/category';
import classNames from 'classnames';
import NavLinkCategory from '../navlink/NavLinkCategory';
import React, { useState } from 'react';

import './NavBarDropDown.css';

type Props = {
  categories: Category[]
}

export default function NavBarDropDown({ categories }: Props) {
  const [menuVisibility, setMenuVisbility] = useState<boolean>(false);

  const showMenu = () => {
    setMenuVisbility(true);
  }

  const hideMenu = () => {
    setMenuVisbility(false);
  }

  const moreLabelClass = classNames({ 'active': menuVisibility });
  const dropdownClass = classNames({ 'show': menuVisibility });

  return (
    <div className='navbar-more'>
      <div id='navbar-more-label' className={moreLabelClass} onMouseEnter={showMenu} onMouseLeave={hideMenu}>MORE &#9660;</div>
      <div id='navbar-dropdown' className={dropdownClass} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
        { categories.map(cat => <NavLinkCategory key={cat.value} label={cat.label} category={cat.value}/>) }
      </div>
    </div>
  );
}