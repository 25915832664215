import {Environment, RecordSource, Store} from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
} from 'react-relay-network-modern';

const API_URL = process.env.REACT_APP_GRAPHQL_API ?? 'http://localhost:8000/graphql';

const store = new Store(new RecordSource());

const network = new RelayNetworkLayer(
  [
    urlMiddleware({
      url: API_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ],
); // as second arg you may pass advanced options for RRNL

// Export a singleton instance of Relay Environment configured with our network function:
const relayEnv = new Environment({
  network,
  store,
});
export default relayEnv;