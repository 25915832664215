import classNames from 'classnames';
import React from 'react';

import './Alert.css';


type Props = {
  message: string,
  isShown?: boolean,
}

export default function Alert({message, isShown}: Props) {
  const notifClass = classNames({
    "show": isShown === true,
    "hide": isShown === false,
  });
  return <div id='notif' className={notifClass}>{message}</div>;
}