import classNames from 'classnames';
import { getCurrentUrlParams } from '../../routing/query_param_helper';
import ContentBlock from '../core/ContentBlock';
import MMLogo from '../logo/MMLogo';
import NavBarDesktop from './navbar/NavBarDesktop';
import NavBarMobile from './navbar/NavBarMobile';
import { CATEGORIES } from '../../types/category';
import ThemeToggle from './themetoggle/ThemeToggle';
import Link from 'found/Link';

import React from 'react';
import { useEffect, useState } from 'react';

import './Header.css';
import SearchBar from '../search/SearchBar';


const DESKTOP_CATEGORIES = 6;

export default function Header() {
  const [headerVisisble, setHeaderVisible] = useState<boolean>(true);

  useEffect(() => {
    let prevScrollY = 0;
    const handleScroll = () => {
      if (typeof window !== 'undefined') { 
        const currScrollY = window.scrollY;
        const scrollDistance = currScrollY - prevScrollY;
        if (scrollDistance > 40) { // scroll down: hide navbar
          prevScrollY = currScrollY;
          setHeaderVisible(false);
        } else if (scrollDistance < -40) { // scroll up: show navbar
          prevScrollY = currScrollY;
          setHeaderVisible(true);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll)
  }, []);

  const headerClass = classNames({
    "header": true,
    "header-hidden": headerVisisble === false,
  });

  return (
    <div className={headerClass}>
      <ContentBlock>
        <div className="header-main">
          <div className="header-logo-wrapper">
          <Link 
            className="header-logo-link"
            to={{
              pathname: '/',
              query: getCurrentUrlParams(),
            }}>
            <MMLogo />
          </Link>
          </div>
          <div className='header-rightColumn'>
            <div className='header-topRow'>
              <SearchBar />
              <ThemeToggle />
            </div>
            <NavBarDesktop 
              categories={CATEGORIES.slice(0, DESKTOP_CATEGORIES)}
              moreCategories={CATEGORIES.slice(DESKTOP_CATEGORIES)}
              />
          </div>
        </div>
      </ContentBlock>
      <ContentBlock>
        <NavBarMobile categories={CATEGORIES} />
      </ContentBlock>
      <div className="header-horizontalline" />
    </div>
  );
}
