/**
 * @generated SignedSource<<c534378a970e157a3fff67ea32899557>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleHeader$data = {
  readonly headline: string;
  readonly id: string;
  readonly isBearish: boolean;
  readonly isBullish: boolean;
  readonly publishedAt: any;
  readonly themeIndex: number;
  readonly " $fragmentType": "ArticleHeader";
};
export type ArticleHeader$key = {
  readonly " $data"?: ArticleHeader$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleHeader">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleHeader",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBullish",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBearish",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "themeIndex",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "136be6f910b9fe8f2d15b8dfde305a2a";

export default node;
