/**
 * @generated SignedSource<<cb70f8267167c0a040127784341a2d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowHideArticleMutation$variables = {
  articleId: string;
  visible: boolean;
};
export type ShowHideArticleMutation$data = {
  readonly setArticleVisibility: {
    readonly " $fragmentSpreads": FragmentRefs<"Article">;
  } | null;
};
export type ShowHideArticleMutation = {
  response: ShowHideArticleMutation$data;
  variables: ShowHideArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "visible"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "articleId"
  },
  {
    "kind": "Variable",
    "name": "visible",
    "variableName": "visible"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShowHideArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "setArticleVisibility",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Article"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShowHideArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "setArticleVisibility",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themeIndex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isHidden",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isBullish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isBearish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keypoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tickers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "explanation",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e8580160d6fdc9775600a659e618490",
    "id": null,
    "metadata": {},
    "name": "ShowHideArticleMutation",
    "operationKind": "mutation",
    "text": "mutation ShowHideArticleMutation(\n  $articleId: ID!\n  $visible: Boolean!\n) {\n  setArticleVisibility(id: $articleId, visible: $visible) {\n    ...Article\n    id\n  }\n}\n\nfragment Article on Article {\n  id\n  body\n  themeIndex\n  isHidden\n  ...ArticleHeader\n  ...ArticleKeypoints\n  ...ArticleTags\n  ...ArticleExplanation\n  ...ArticleControlBar\n}\n\nfragment ArticleControlBar on Article {\n  id\n  isHidden\n}\n\nfragment ArticleExplanation on Article {\n  explanation\n  isBullish\n  isBearish\n}\n\nfragment ArticleHeader on Article {\n  id\n  headline\n  publishedAt\n  isBullish\n  isBearish\n  themeIndex\n}\n\nfragment ArticleKeypoints on Article {\n  keypoints\n}\n\nfragment ArticleTags on Article {\n  tags\n  tickers\n}\n"
  }
};
})();

(node as any).hash = "9817922dbb605e5f6ff316baa2004afe";

export default node;
