import type { ArticleControlBar$key } from './__generated__/ArticleControlBar.graphql';
import { ShowHideArticle } from '../../mutations/ShowHideArticle';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import React, { useState } from 'react';

import './ArticleControlBar.css';
import EyeIcon from '../icons/EyeIcon';
import { LoadingIcon } from '../icons/LoadingIcon';


const fragment = graphql`
fragment ArticleControlBar on Article {
  id
  isHidden
}
`;

type Props = {
  article: ArticleControlBar$key,
}

export default function ArticleControlBar({article}: Props) {
  const data = useFragment(fragment, article);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    ShowHideArticle({
      articleId: data.id,
      visible: data.isHidden,
      onCompleted: (data) => {
        setIsLoading(false);
      },
      onError: (_err) => { setIsLoading(false); },
    });
  }

  const toggleVisisbilityKeyDown = (event: any) => {
    if(event.key === 'Enter') {
      toggleVisibility();
    }
  }

  return (
    <div className='control-bar'>
      <div className='control-bar-text'>Article ID: {data.id}</div>
      <div className='control-bar-button' tabIndex={0} onClick={toggleVisibility} onKeyDown={toggleVisisbilityKeyDown}>
        {isLoading ? <LoadingIcon width={32} /> : <EyeIcon width={32} hasSlash={!data.isHidden} />}
      </div>
    </div>
  )
}