import type { SearchByTickerPage_content$key } from './__generated__/SearchByTickerPage_content.graphql';
import type { SearchByTickerPagePaginationQuery } from './__generated__/SearchByTickerPagePaginationQuery.graphql';
import Alert from '../common/Alert';
import Article from '../article/Article';
import PageBottom from '../common/PageBottom';
import { graphql } from 'babel-plugin-relay/macro';
import { usePaginationFragment } from 'react-relay';
import React, { useEffect, useState } from 'react';

import './ArticlesGrid.css';


const fragment = graphql`
fragment SearchByTickerPage_content on Ticker
@argumentDefinitions(
  cursor: { type: "StandardCursor" }
  count: { type: "Int", defaultValue: 10 }
)
@refetchable(queryName: "SearchByTickerPagePaginationQuery") {
  articles(first: $count, after: $cursor) @connection(key: "SearchByTicker_articles") {
    edges {
      node {
        id
        ...Article
      }
    }
  }
}
`;

export const searchByTickerPageQuery = graphql`
  query SearchByTickerPageQuery($code: String!) {
    ticker(code: $code) {
      ...SearchByTickerPage_content
    }
  }
`;

type Props = {
  ticker: SearchByTickerPage_content$key,
}

export default function SearchByTickerPage({ ticker }: Props) {
  const { data, loadNext, isLoadingNext, hasNext } = usePaginationFragment<SearchByTickerPagePaginationQuery, SearchByTickerPage_content$key>(fragment, ticker);
  
  const [showAlert, setShowAlert] = useState<boolean|undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ticker]);

  const showAlertHandle = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  }

  return (
    <div>
      <Alert message='Link Copied!' isShown={showAlert} />
      <div className='content-container'>
        <div className='articles-container'>
          {data.articles?.edges?.map(edge => (edge?.node && <div className='article-wrapper'><Article key={edge.node.id} article={edge.node} showAlert={showAlertHandle} /></div>))}
        </div>
        <PageBottom 
          hasData={data.articles?.edges != null && data.articles?.edges?.length > 0}
          hasNext={hasNext}
          loadNext={loadNext}
          isLoading={isLoadingNext}/>
      </div>
    </div>
  )
}