/**
 * @generated SignedSource<<0733a917219574b96ee6e924a49c29f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleTags$data = {
  readonly tags: ReadonlyArray<string | null>;
  readonly tickers: ReadonlyArray<string | null>;
  readonly " $fragmentType": "ArticleTags";
};
export type ArticleTags$key = {
  readonly " $data"?: ArticleTags$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleTags">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleTags",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tickers",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "96907e690671824cf63494482c355f89";

export default node;
