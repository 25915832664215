import type { ArticlePage_article$key } from './__generated__/ArticlePage_article.graphql';
import type { ArticlePageQuery$data } from './__generated__/ArticlePageQuery.graphql';
import Alert from '../common/Alert';
import Article from '../article/Article';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import React, { useEffect, useState } from 'react';

import './ArticlesGrid.css';

const articleFragment = graphql`
fragment ArticlePage_article on Query {
  article(id: $articleId) {
    ...Article
  }
}
`;

export const articlePageQuery = graphql`
  query ArticlePageQuery($articleId: ID) {
    ...ArticlePage_article
  }
`;

export default function ArticlePage(article: ArticlePageQuery$data) {
  const articleData = useFragment<ArticlePage_article$key>(articleFragment, article);

  const [showAlert, setShowAlert] = useState<boolean|undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showAlertHandle = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  }

  return (
    <div>
      <Alert message='Link Copied!' isShown={showAlert} />
      <div className='content-container'>
        {articleData.article && <Article article={articleData.article} showAlert={showAlertHandle} />}
      </div>
    </div>
  )
}