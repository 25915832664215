import type { CategoryEnum } from '../../../types/category';
import NavLink from './NavLink';
import React from 'react';


type Props = {
  label: string,
  category: CategoryEnum,
}

export default function NavLinkCategory({ label, category }: Props) {
  return <NavLink label={label} path={`/category/${category}`} />;
}