import React from 'react';

import './NoArticleCard.css';

export default function NoArticleCard() {
  return (
    <div className='no-article-card'>
      No article found
    </div>
  )
}