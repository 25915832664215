import classNames from 'classnames';
import { TrendType, getTrendIcon } from '../icons/TrendIcon';
import React from 'react';

import './ArticleBadge.css';


type Props = {
  badgeType: TrendType,
}

export default function ArticleBadge({ badgeType }: Props) {
  const badgeClass = classNames({
    'badge-area': true,
    'badge-bullish': badgeType === 'bullish',
    'badge-bearish': badgeType === 'bearish',
  });
  const arrowClass = classNames({
    'badge-arrow-bullish': badgeType === 'bullish',
    'badge-arrow-bearish': badgeType === 'bearish',
  })
  
  return (
    <div className={badgeClass}>
      <div className={arrowClass}>
        {getTrendIcon(badgeType)}
      </div>  
    </div>
  );
}