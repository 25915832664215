import type { HomePage_content$key } from './__generated__/HomePage_content.graphql';
import type { HomePagePaginationQuery } from './__generated__/HomePagePaginationQuery.graphql';
import type { HomePageQuery$data } from './__generated__/HomePageQuery.graphql';
import Alert from '../common/Alert';
import Article from '../article/Article';
import PageBottom from '../common/PageBottom';
import { graphql } from 'babel-plugin-relay/macro';
import { usePaginationFragment } from 'react-relay';
import React, { useEffect, useState } from 'react';

import './ArticlesGrid.css';



const fragment = graphql`
fragment HomePage_content on Query
@argumentDefinitions(
  cursor: { type: "StandardCursor" }
  count: { type: "Int", defaultValue: 10 }
)
@refetchable(queryName: "HomePagePaginationQuery") {
  homepageArticles(first: $count, after: $cursor) @connection(key: "HomePage_homepageArticles") {
    edges {
      node {
        id
        ...Article
      }
    }
  }
}
`;

export const homePageQuery = graphql`
  query HomePageQuery {
    ...HomePage_content
  }
`;

export default function HomePage(homepageArticles: HomePageQuery$data) {
  const { data, loadNext, isLoadingNext, hasNext } = usePaginationFragment<HomePagePaginationQuery, HomePage_content$key>(fragment, homepageArticles);

  const [showAlert, setShowAlert] = useState<boolean|undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showAlertHandle = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  }

  return (
    <div>
      <Alert message='Link Copied!' isShown={showAlert} />
      <div className='content-container'>
        <div className='articles-container'>
          {data.homepageArticles?.edges?.map(edge => (edge?.node && <div className='article-wrapper'><Article key={edge.node.id} article={edge.node} showAlert={showAlertHandle} /></div>))}
        </div>
        <PageBottom 
          hasData={data.homepageArticles?.edges != null && data.homepageArticles?.edges?.length > 0}
          hasNext={hasNext}
          loadNext={loadNext}
          isLoading={isLoadingNext}/>
      </div>
    </div>
  )
}