import type { SearchByTextPage_content$key } from './__generated__/SearchByTextPage_content.graphql';
import type { SearchByTextPagePaginationQuery } from './__generated__/SearchByTextPagePaginationQuery.graphql';
import Alert from '../common/Alert';
import Article from '../article/Article';
import PageBottom from '../common/PageBottom';
import { graphql } from 'babel-plugin-relay/macro';
import { usePaginationFragment } from 'react-relay';
import React, { useEffect, useState } from 'react';

import './ArticlesGrid.css';


const fragment = graphql`
fragment SearchByTextPage_content on SearchNode
@argumentDefinitions(
  cursor: { type: "String" }
  count: { type: "Int", defaultValue: 50 }
)
@refetchable(queryName: "SearchByTextPagePaginationQuery") {
  articles(first: $count, after: $cursor) @connection(key: "SearchByText_articles") {
    edges {
      node {
        id
        ...Article
      }
    }
  }
}
`;

export const searchByTextPageQuery = graphql`
  query SearchByTextPageQuery($query: String!) {
    searchNode(query: $query) {
      ...SearchByTextPage_content
    }
  }
`;

type Props = {
  searchNode: SearchByTextPage_content$key,
}

export default function SearchByTextPage({ searchNode }: Props) {
  const { data, loadNext, isLoadingNext, hasNext } = usePaginationFragment<SearchByTextPagePaginationQuery, SearchByTextPage_content$key>(fragment, searchNode);
  
  const [showAlert, setShowAlert] = useState<boolean|undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchNode]);

  const showAlertHandle = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  }

  return (
    <div>
      <Alert message='Link Copied!' isShown={showAlert} />
      <div className='content-container'>
        <div className='articles-container'>
          {data.articles?.edges?.map(edge => (edge?.node && <div className='article-wrapper'><Article key={edge.node.id} article={edge.node} showAlert={showAlertHandle}/></div>))}
        </div>
        <PageBottom 
          hasData={data.articles?.edges != null && data.articles?.edges?.length > 0}
          hasNext={hasNext}
          pageSize={50}
          loadNext={loadNext}
          isLoading={isLoadingNext}/>
      </div>
    </div>
  )
}