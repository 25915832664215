import type { Category } from '../../../types/category';
import NavLink from '../navlink/NavLink';
import NavLinkCategory from '../navlink/NavLinkCategory';
import React from 'react';

import './NavBarMobile.css';

type Props = {
  categories: Category[],
}

export default function NavBarMobile({ categories }: Props) {
  return (
    <div className='navbarmobile-area'>
      <div className='navbarmobile-links'>
        <NavLink label='HOME' path='/' />
        { categories.map(cat => <NavLinkCategory key={cat.value} label={cat.label} category={cat.value} />) }
      </div>
    </div>
  );
}
