import type { Theme } from '../context/ThemeContext';


export function insertUrlParam(key: string, value: string) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({path: newurl}, '', newurl);
  }
}

export function getCurrentUrlParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams);
}

export function getCurrentUrlParamsWith(key: string, value: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  return Object.fromEntries(searchParams);
}


export function getArticleUrl(articleId: string) {
  const searchParams = new URLSearchParams(window.location.search);
  const searchParamsStr = searchParams.toString();
  return `${window.location.protocol}//${window.location.host}/article/${articleId}${searchParamsStr.length > 0 ? '?' + searchParamsStr : ''}`;
}


const DARK_MODE_PARAM = 'dark';

export function addDarkModeUrlParam(theme: Theme) {
  insertUrlParam(DARK_MODE_PARAM, theme === 'light' ? '0' : '1');
}

export function getCurrentThemeUrlParam(): Theme | null {
  const searchParams = new URLSearchParams(window.location.search);
  const darkParam = searchParams.get(DARK_MODE_PARAM);
  if (darkParam == null) {
    return null;
  }
  
  if (darkParam === '0' || darkParam.toLocaleLowerCase() === 'false') {
    return 'light';
  }
  return 'dark';
}