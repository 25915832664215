import React from 'react';

import './ContentBlock.css';


type Props = {
  children: React.ReactNode,
}

export default function ContentBlock({children}: Props) {
  return (
    <div className="contentBlock-root">
      <div className="contentBlock-body">
        {children}
      </div>
    </div>
  )
}