import './MMLogo.css';

import React from 'react';


export default function MMLogo() {

  return (
    <svg className="logo" viewBox="0 0 416 393" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_704_1727)">
    <path d="M208 242.024C232.463 202.921 311.031 170.563 416 154.308V170.503C407.216 171.89 398.632 173.391 390.265 175H392V393H336V187.785C328.195 190.009 320.685 192.34 313.492 194.77C280.958 205.761 255.781 218.492 239.062 231.846C229.262 239.673 223.067 247.112 219.595 254H196.405C192.933 247.112 186.738 239.673 176.938 231.846C160.219 218.492 135.042 205.761 102.508 194.77C95.3153 192.34 87.8054 190.009 80 187.785V393H24V175H25.7354C17.3683 173.391 8.7841 171.89 0 170.503V154.308C104.969 170.563 183.537 202.921 208 242.024Z" fill="currentColor"/>
    <path d="M301.049 182.156C285.076 146.69 249.421 122 208 122C166.579 122 130.924 146.69 114.951 182.156C160.807 198.548 193.677 219.13 208 242.024C222.323 219.13 255.193 198.548 301.049 182.156Z" fill="url(#sunshine_gradient)"/>
    <path d="M200 0.140194C202.655 0.0469894 205.322 0 208 0C210.678 0 213.345 0.0469894 216 0.140194V106.267C213.356 106.09 210.689 106 208 106C205.311 106 202.644 106.09 200 106.267V0.140194Z" fill="url(#sunshine_gradient)"/>
    <path d="M306.38 22.704C301.602 20.3645 296.728 18.19 291.767 16.1882L248.589 113.166C253.63 115.013 258.51 117.194 263.202 119.682L306.38 22.704Z" fill="url(#sunshine_gradient)"/>
    <path d="M124.233 16.1883C119.271 18.1902 114.398 20.3647 109.62 22.7042L152.797 119.682C157.489 117.194 162.369 115.013 167.41 113.167L124.233 16.1883Z" fill="url(#sunshine_gradient)"/>
    <path d="M36.2844 80.1524C39.7136 76.0632 43.2861 72.0983 46.9943 68.2655L125.863 139.279C122.033 142.994 118.454 146.965 115.154 151.166L36.2844 80.1524Z" fill="url(#sunshine_gradient)"/>
    <path d="M379.715 80.1522C376.286 76.0631 372.714 72.0982 369.006 68.2653L290.137 139.279C293.967 142.993 297.546 146.965 300.846 151.166L379.715 80.1522Z" fill="url(#sunshine_gradient)"/>
    </g>
    <defs>
    <radialGradient id="sunshine_gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(208 187) rotate(-90) scale(148.5 299.339)">
    <stop stop-color="#F9B576"/>
    <stop offset="1" stop-color="currentColor"/>
    </radialGradient>
    <clipPath id="clip0_704_1727">
    <rect width="416" height="393" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}