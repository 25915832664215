import React from 'react';

type Props = {
  width: number,
  hasSlash?: boolean,
}

export default function EyeIcon({width, hasSlash = false}: Props) {
  if (hasSlash) {
    return (
      <svg width={width} viewBox="0 0 33 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0195 21.217C18.1992 21.217 20.1914 20.8303 22.0078 20.2092L20.4727 18.674C19.0781 19.1076 17.6016 19.3654 16.0195 19.3654C8.29688 19.3654 2.05078 12.8264 2.05078 11.174C2.05078 10.3537 4.21875 7.62323 7.67578 5.54901L6.22266 4.09588C2.32031 6.50995 0 9.75604 0 11.174C0 13.5646 6.66797 21.217 16.0195 21.217ZM16.0195 1.13104C13.9805 1.13104 12.1289 1.4826 10.4062 2.05682L11.9531 3.59198C13.2422 3.20526 14.5664 2.9826 16.0195 2.9826C23.7305 2.9826 29.9766 9.77948 29.9766 11.174C29.9766 12.1232 27.9258 14.6779 24.6797 16.6701L26.1211 18.1115C29.8242 15.7092 32.0273 12.5568 32.0273 11.174C32.0273 8.77167 25.4883 1.13104 16.0195 1.13104ZM16.0195 17.7365C17.0508 17.7365 18.0234 17.4787 18.8906 17.0568L10.1016 8.26776C9.66797 9.13495 9.42188 10.1193 9.42188 11.174C9.43359 14.7482 12.3516 17.7365 16.0195 17.7365ZM22.0312 13.7404C22.3945 12.9553 22.5938 12.0764 22.5938 11.174C22.5938 7.50604 19.6641 4.61151 16.0195 4.61151C15.0938 4.61151 14.2266 4.81073 13.4414 5.15057L22.0312 13.7404ZM25.1367 21.3224C25.4883 21.6857 26.0508 21.7092 26.4141 21.3224C26.8008 20.9357 26.7656 20.4084 26.4141 20.0451L6.82031 0.463074C6.46875 0.111511 5.89453 0.111511 5.53125 0.463074C5.19141 0.802918 5.19141 1.40057 5.53125 1.74042L25.1367 21.3224Z"/>
      </svg>
    )
  }

  return (
    <svg width={width} viewBox="0 0 33 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0195 20.1994C25.4883 20.1994 32.0273 12.547 32.0273 10.1564C32.0273 7.75402 25.4766 0.113403 16.0195 0.113403C6.67969 0.113403 0 7.75402 0 10.1564C0 12.547 6.66797 20.1994 16.0195 20.1994ZM16.0195 18.3478C8.29688 18.3478 2.05078 11.8087 2.05078 10.1564C2.05078 8.76184 8.29688 1.96496 16.0195 1.96496C23.707 1.96496 29.9766 8.76184 29.9766 10.1564C29.9766 11.8087 23.707 18.3478 16.0195 18.3478ZM16.0195 16.7189C19.6641 16.7189 22.5938 13.7306 22.5938 10.1564C22.5938 6.4884 19.6641 3.59387 16.0195 3.59387C12.3516 3.59387 9.41016 6.4884 9.42188 10.1564C9.44531 13.7306 12.3516 16.7189 16.0195 16.7189ZM16.0195 12.3361C14.8008 12.3361 13.8164 11.3517 13.8164 10.1564C13.8164 8.94934 14.8008 7.97668 16.0195 7.97668C17.2266 7.97668 18.2109 8.94934 18.2109 10.1564C18.2109 11.3517 17.2266 12.3361 16.0195 12.3361Z"/>
    </svg>
  )
}
