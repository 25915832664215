export type CategoryEnum = "ASIA" | "CHINA" | "CRYPTO" | "EARNINGS" | "ENERGY" | "ENTERTAINMENT" | "EUROPE" | "FINANCE" | "HEALTH_SCIENCE" | "REAL_ESTATE" | "LIFE" | "TECH" | "US";

export type Category = {
  label: string,
  value: CategoryEnum,
}

export const CATEGORIES: Category[] = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'CHINA',
    value: 'CHINA',
  },
  {
    label: 'ASIA',
    value: 'ASIA',
  },
  {
    label: 'EUROPE',
    value: 'EUROPE',
  },
  {
    label: 'TECH',
    value: 'TECH',
  },
  {
    label: 'FINANCE',
    value: 'FINANCE',
  },
  {
    label: 'EARNINGS',
    value: 'EARNINGS',
  },
  {
    label: 'CRYPTO',
    value: 'CRYPTO',
  },
  {
    label: 'REAL ESTATE',
    value: 'REAL_ESTATE',
  },
  {
    label: 'HEALTH & SCIENCE',
    value: 'HEALTH_SCIENCE',
  },
  {
    label: 'ENERGY',
    value: 'ENERGY',
  },
  {
    label: 'LIFE',
    value: 'LIFE',
  },
  {
    label: 'ENTERTAINMENT',
    value: 'ENTERTAINMENT',
  },
];