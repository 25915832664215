import type { ArticleTags$key } from './__generated__/ArticleTags.graphql';
import { getCurrentUrlParams } from '../../routing/query_param_helper';
import Link from 'found/Link';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import React from 'react';

import './ArticleTags.css';
import './SectionHeader.css';


type SmallLinkProps = {
  label: string,
  urlPath: string,
  isLast?: boolean,
}

function SmallLink({label, urlPath, isLast = false}: SmallLinkProps) {
  return (
    <span>
      <Link
        className='article-tags-link'
        to={{
          pathname: urlPath,
          query: getCurrentUrlParams(),
        }}
        activeClassName='article-tags-link-active'
        exact={true}>
        {label}
      </Link>
      {isLast === false && <span className='tag-comma'>,</span>}
    </span>
  );
}


type TagLinkProps = {
  tag: string | null,
  isLast?: boolean,
}

function TagLink({tag, isLast = false}: TagLinkProps) {
  if (tag == null) return null;

  return <SmallLink label={tag} urlPath={`/tag/${tag}`} isLast={isLast} />
}


type TickerLinkProps = {
  code: string | null,
  isLast?: boolean,
}

function TickerLink({code, isLast = false}: TickerLinkProps) {
  if (code == null) return null;

  return <SmallLink label={`#${code}`} urlPath={`/ticker/${code}`} isLast={isLast} />
}


const fragment = graphql`
fragment ArticleTags on Article {
  tags
  tickers
}
`;

type Props = {
  article: ArticleTags$key,
}

export default function ArticleTags({ article }: Props) {
  const data = useFragment(fragment, article);
  return (
    <>
    {(data.tags != null && data.tags.length > 0) && <div className='article-tags-section'>
      <div className='section-header'>TAGS</div>
      <div className='article-tags-content'>
        {data.tags.slice(0, -1).map(tag => <TagLink key={tag} tag={tag} />)}
        {data.tags.slice(-1).map(tag => <TagLink key={tag} tag={tag} isLast={true} />)}
      </div>
    </div>}
    {(data.tickers != null && data.tickers.length > 0) && <div className='article-tags-section'>
      <div className='section-header'>SYMBOLS</div>
      <div className='article-tags-content'>
        {data.tickers.slice(0, -1).map(code => <TickerLink key={code} code={code} />)}
        {data.tickers.slice(-1).map(code => <TickerLink key={code} code={code} isLast={true} />)}
      </div>
    </div>}
    </>
  )
}