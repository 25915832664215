import React from 'react';

type Props = {
  width: number;
}

export function LoadingIcon({ width }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} x="0px" y="0px" viewBox="0 0 33 22" enable-background="new 0 0 0 0">
      <circle fill="currentColor" stroke="none" cx="3" cy="11" r="3">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"/>    
      </circle>
      <circle fill="currentColor" stroke="none" cx="16" cy="11" r="3">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite" 
          begin="0.2"/>       
      </circle>
      <circle fill="currentColor" stroke="none" cx="30" cy="11" r="3">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite" 
          begin="0.3"/>     
      </circle>
    </svg>
  )
}