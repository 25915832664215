/**
 * @generated SignedSource<<e725c5f418f8c1d1bcbec619d474b7aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Article$data = {
  readonly body: ReadonlyArray<string | null>;
  readonly id: string;
  readonly isHidden: boolean;
  readonly themeIndex: number;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleControlBar" | "ArticleExplanation" | "ArticleHeader" | "ArticleKeypoints" | "ArticleTags">;
  readonly " $fragmentType": "Article";
};
export type Article$key = {
  readonly " $data"?: Article$data;
  readonly " $fragmentSpreads": FragmentRefs<"Article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "themeIndex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleHeader"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleKeypoints"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleTags"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleExplanation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleControlBar"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "0c13c95c55280d4d0c25f5d470e384de";

export default node;
