import React from 'react';
import ReactDOM from 'react-dom/client';
import RelayEnvironment from './relay_util/RelayEnvironment';
import createFarceRouter from 'found/createFarceRouter';
import BrowserProtocol from 'farce/BrowserProtocol';
import queryMiddleware from 'farce/queryMiddleware';
import { Resolver } from 'found-relay';

import routes from './routing/routes';

import './index.css';

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,
});

const foundRelayResolver = (new Resolver(RelayEnvironment) as any);
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router resolver={foundRelayResolver}/>
  </React.StrictMode>
);