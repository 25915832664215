import type {
  ShowHideArticleMutation,
  ShowHideArticleMutation$data,
} from "./__generated__/ShowHideArticleMutation.graphql";

import { graphql } from "babel-plugin-relay/macro";
import RelayEnvironment from "../relay_util/RelayEnvironment";
import { commitMutation } from "react-relay";

type Config = {
  articleId: string,
  visible: boolean,
  onCompleted?:
    | ((response: ShowHideArticleMutation$data) => void)
    | null;
  onError?: (error: any) => void;
};


const mutation = graphql`
  mutation ShowHideArticleMutation($articleId: ID!, $visible: Boolean!) {
    setArticleVisibility(id: $articleId, visible: $visible) {
      ...Article
    }
  }
`;

export function ShowHideArticle({
  articleId,
  visible,
  onCompleted,
  onError,
}: Config) {
  return commitMutation<ShowHideArticleMutation>(RelayEnvironment, {
    mutation,
    onCompleted: (response, _error) => {
      if (onCompleted != null) {
        onCompleted(response);
      }
    },
    onError,
    variables: {
      articleId,
      visible,
    },
  });
}