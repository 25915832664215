/**
 * @generated SignedSource<<e8af9d8af6c3be9cb8cd3add8e205768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticlePageQuery$variables = {
  articleId?: string | null;
};
export type ArticlePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ArticlePage_article">;
};
export type ArticlePageQuery = {
  response: ArticlePageQuery$data;
  variables: ArticlePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticlePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ArticlePage_article"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticlePageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "articleId"
          }
        ],
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "themeIndex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isHidden",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isBullish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isBearish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keypoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tickers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "explanation",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a025f30df08e6847d72905d7e59d0470",
    "id": null,
    "metadata": {},
    "name": "ArticlePageQuery",
    "operationKind": "query",
    "text": "query ArticlePageQuery(\n  $articleId: ID\n) {\n  ...ArticlePage_article\n}\n\nfragment Article on Article {\n  id\n  body\n  themeIndex\n  isHidden\n  ...ArticleHeader\n  ...ArticleKeypoints\n  ...ArticleTags\n  ...ArticleExplanation\n  ...ArticleControlBar\n}\n\nfragment ArticleControlBar on Article {\n  id\n  isHidden\n}\n\nfragment ArticleExplanation on Article {\n  explanation\n  isBullish\n  isBearish\n}\n\nfragment ArticleHeader on Article {\n  id\n  headline\n  publishedAt\n  isBullish\n  isBearish\n  themeIndex\n}\n\nfragment ArticleKeypoints on Article {\n  keypoints\n}\n\nfragment ArticlePage_article on Query {\n  article(id: $articleId) {\n    ...Article\n    id\n  }\n}\n\nfragment ArticleTags on Article {\n  tags\n  tickers\n}\n"
  }
};
})();

(node as any).hash = "ababc6aea61915062c8da849f4162a48";

export default node;
