import type { Article$key } from './__generated__/Article.graphql';
import classNames from 'classnames';
import { AdminModeContext } from '../../context/AdminModeContext';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import React, { useContext } from 'react';
import ArticleHeader from './ArticleHeader';
import ArticleKeypoint from './ArticleKeypoints';

import './Article.css';
import ArticleTags from './ArticleTags';
import ArticleExplanation from './ArticleExplanation';
import ArticleControlBar from './ArticleControlBar';


const THEMES_COUNT = 7;

export const fragment = graphql`
fragment Article on Article {
  id
  body
  themeIndex
  isHidden
  ...ArticleHeader
  ...ArticleKeypoints
  ...ArticleTags
  ...ArticleExplanation
  ...ArticleControlBar
}
`;

type Props = {
  article: Article$key,
  showAlert?: () => void,
}

export default function Article({ article, showAlert }: Props) {
  const { isAdminMode } = useContext(AdminModeContext);

  const data = useFragment(fragment, article);
  const themeIndex = (data.themeIndex ?? 0) % THEMES_COUNT;

  const bgClass = classNames('article-bg', `article-theme${themeIndex + 1}`, data.isHidden && 'article-hidden');
  return (
    <div className='article-box'>
      {isAdminMode && <ArticleControlBar article={data} />}
      <div className={bgClass}>
        <ArticleHeader article={data} showAlert={showAlert} />
        <ArticleKeypoint article={data} />
        <div className='article-body'>{data.body.map((paragraph, i: number) => (<div key={i}>{paragraph}</div>))}</div>
        <div className='article-optional-fields'>
          <ArticleExplanation article={data} />
          <ArticleTags article={data} />
        </div>
      </div>
    </div>
  )
}