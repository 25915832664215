import LoadNextTrigger from './LoadNextTrigger';
import NoArticleCard from './NoArticleCard';
import React from 'react';


type Props = {
  hasData: boolean,
  hasNext: boolean,
  loadNext: (pageSize: number) => any,
  isLoading: boolean,
  pageSize?: number,
}

export default function PageBottom({ hasData, hasNext, loadNext, isLoading, pageSize = 10 }: Props) {
  if (!hasData && !hasNext) {
    return <NoArticleCard />;
  }

  if (hasNext) {
    return <LoadNextTrigger loadNext={loadNext} isLoading={isLoading} pageSize={pageSize} />;
  }

  return null;
}