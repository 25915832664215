import type { ArticleHeader$key } from './__generated__/ArticleHeader.graphql';
import ArticleBadge from '../badge/ArticleBadge';
import { getArticleUrl } from '../../routing/query_param_helper';
import classNames from 'classnames';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import React from 'react';

import './ArticleHeader.css';
import CopyIcon from '../icons/CopyIcon';


const fragment = graphql`
fragment ArticleHeader on Article {
  id
  headline
  publishedAt
  isBullish
  isBearish
  themeIndex
}
`;

type Props = {
  article: ArticleHeader$key,
  showAlert?: () => void,
}

const HEADER_THEMES_COUNT = 29;

export default function ArticleHeader({ article, showAlert }: Props) {
  const data = useFragment(fragment, article);

  const copyArticleURL = () => {
    navigator.clipboard.writeText(getArticleUrl(data.id));
    if (showAlert) {
      showAlert();
    }
  }

  const copyArticleURLOnKeyDown = (event: any) => {
    if(event.key === 'Enter') {
      copyArticleURL();
    }
  }

  const publishedDate = new Date(data.publishedAt);
  const isBullish = data.isBullish ?? false;
  const isBearish = data.isBearish ?? false;

  const badgeType = isBullish ? 'bullish' : (isBearish ? 'bearish' : null);
  const headerContentClass = classNames({
    'article-header-content': true,
    'article-header-content-neutral': !isBullish && !isBearish,
  });
  const shareButtonClass = classNames({
    'article-share-button': true,
    'article-share-button-neutral': !isBullish && !isBearish,
  });

  const themeIndex = (data.themeIndex ?? 0) % HEADER_THEMES_COUNT;
  const headerClass = classNames('article-header', `article-header-theme-${themeIndex}`);

  return (
    <div className={headerClass}>
      <div className={headerContentClass}>
        <div className='article-headline'>
          {data.headline}
        </div>
        <div className='article-published-at'>
          {new Intl.DateTimeFormat(
            undefined,
            {weekday: "short", month: "short", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: "h12", timeZoneName: "short"}
            ).format(publishedDate)}
        </div>
      </div>
      <div className='article-header-right'>
        {badgeType != null && <ArticleBadge badgeType={badgeType} />}
        <div className={shareButtonClass} onClick={copyArticleURL} tabIndex={0} onKeyDown={copyArticleURLOnKeyDown}>
          <CopyIcon width={20}/>
        </div>
      </div>
    </div>
  )
}