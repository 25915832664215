import type { Theme } from '../../context/ThemeContext';
import { AdminModeContext } from '../../context/AdminModeContext';
import { ThemeContext } from '../../context/ThemeContext';
import { addDarkModeUrlParam, getCurrentThemeUrlParam } from '../../routing/query_param_helper';
import ContentBlock from '../core/ContentBlock';
import Header from '../header/Header';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';


import './App.css';

const isAdminMode = process.env.REACT_APP_ADMIN_MODE === 'true';

type Props = {
  children: React.ReactNode,
}

function getInitialTheme(urlTheme: Theme | null): Theme {
  if (urlTheme != null) {
    return urlTheme;
  }
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
}

function updateMetaThemeColor(theme: Theme) {
  var themeColor;
  if(theme === 'dark') {
      themeColor = '#171717';
  } else {
      themeColor = '#FAFAFA';
  }

  const themeColorTag = document.querySelector("meta[name='theme-color']");
  if (themeColorTag != null) {
    (themeColorTag as any).content = themeColor;
  }
}


function App({ children }: Props) {
  const urlTheme = getCurrentThemeUrlParam();
  const initialTheme = getInitialTheme(urlTheme);

  const [theme, setTheme] = useState<Theme>(initialTheme);

  useEffect(() => {
    updateMetaThemeColor(theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((currTheme) => {
      const newTheme = currTheme === 'light' ? 'dark' : 'light';
      addDarkModeUrlParam(newTheme);
      return newTheme;
    });
  };

  const appClass = classNames({
    "app-lightmode": theme === 'light',
    "app-darkmode": theme === 'dark',
  })

  return (
    <AdminModeContext.Provider value={{ isAdminMode }}>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <div className={appClass}>
          <Header />
          <ContentBlock>
            <div>
              {children}
            </div>
          </ContentBlock>
        </div>
      </ThemeContext.Provider>
    </AdminModeContext.Provider>
  );
}

export default App;
