/**
 * @generated SignedSource<<98b3b7fe6bd8cfd662c87bf70718730e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleExplanation$data = {
  readonly explanation: string;
  readonly isBearish: boolean;
  readonly isBullish: boolean;
  readonly " $fragmentType": "ArticleExplanation";
};
export type ArticleExplanation$key = {
  readonly " $data"?: ArticleExplanation$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleExplanation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleExplanation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "explanation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBullish",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBearish",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "943923056915095ce56fa2ca83997aa0";

export default node;
