export type TrendType = 'bullish' | 'bearish';

export function getTrendIcon(badgeType: TrendType) {
  switch (badgeType) {
    case 'bullish':
      return (
        <svg viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M43.8712 2.6806L48.2186 7.02806L33.4856 21.7611L23.5529 11.8284C22.3754 10.6509 20.4734 10.6509 19.296 11.8284L1.1816 29.973C0.00416404 31.1504 0.00416404 33.0524 1.1816 34.2298C2.35904 35.4073 4.26105 35.4073 5.43848 34.2298L21.4093 18.2288L31.3421 28.1615C32.5195 29.339 34.4215 29.339 35.5989 28.1615L52.4755 11.3151L56.823 15.6626C57.7589 16.5985 59.3892 15.9343 59.3892 14.6059V1.62393C59.4194 0.77859 58.7552 0.114395 57.9098 0.114395H44.958C43.5995 0.114395 42.9353 1.74469 43.8712 2.6806Z" fill="currentColor"/>
        </svg>
      );
    case 'bearish':
      return (
        <svg viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M43.8712 32.777L48.2187 28.4295L33.4856 13.6965L23.5529 23.6292C22.3754 24.8066 20.4734 24.8066 19.296 23.6292L1.1816 5.48463C0.00416401 4.30719 0.00416401 2.40518 1.1816 1.22774C2.35904 0.0503066 4.26105 0.0503066 5.43848 1.22774L21.4093 17.2288L31.3421 7.29607C32.5195 6.11863 34.4215 6.11863 35.5989 7.29607L52.4755 24.1425L56.823 19.795C57.7589 18.8591 59.3892 19.5233 59.3892 20.8517V33.8035C59.3892 34.6488 58.725 35.313 57.8797 35.313H44.9279C43.5995 35.3432 42.9353 33.7129 43.8712 32.777Z" fill="currentColor"/>
        </svg>
      );
  }
}