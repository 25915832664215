import type { ArticleExplanation$key } from './__generated__/ArticleExplanation.graphql';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { getTrendIcon } from '../icons/TrendIcon';
import React from 'react';

import './ArticleExplanation.css';
import './SectionHeader.css';


const fragment = graphql`
fragment ArticleExplanation on Article {
  explanation
  isBullish
  isBearish
}
`;

type Props = {
  article: ArticleExplanation$key,
}

export default function ArticleExplanation({ article }: Props) {
  const data = useFragment(fragment, article);

  if (data.explanation == null || data.explanation.length === 0) {
    return null;
  }

  const isBullish = data.isBullish ?? false;
  const isBearish = data.isBearish ?? false;

  const trendType = isBullish ? 'bullish' : (isBearish ? 'bearish' : null);
  const iconClass = trendType === 'bullish' ? 'article-explanation-bullish-icon' : 'article-explanation-bearish-icon';
  const header = data.isBullish ? 'ARTICLE IS BULLISH 🐂' : ( data.isBearish ? 'ARTICLE IS BEARISH 🐻' : 'ARTICLE IS NEUTRAL 😐'); 

  return (
    <div className='article-explanation-wrapper'>
      <div className='article-explanation-trend-layer'>
        {trendType && <div className={iconClass}>{getTrendIcon(trendType)}</div>}
      </div>
      <div className='article-explanation-section'>
        <div className='section-header'>{header}</div>
        <div className='article-explanation-content'>{data.explanation}</div>
      </div>
    </div>
  )
}