import React from 'react';

import '../pages/ArticlesGrid.css';
import './LoadingArticle.css';


const NUM_LOADING_ARTICLES = 6;

export default function LoadingArticles() {
  return (
    <div className='content-container'>
      <div className='articles-container'>
        {
          [...Array(NUM_LOADING_ARTICLES)].map((e, i) => {
            return (
              <div key={i} className='article-wrapper'>
                <div className='article-box animated-background'/>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}