import type { ArticleKeypoints$key } from './__generated__/ArticleKeypoints.graphql';
import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import React from 'react';

import './ArticleKeypoints.css';
import './SectionHeader.css';


const fragment = graphql`
fragment ArticleKeypoints on Article {
  keypoints
}
`;

type Props = {
  article: ArticleKeypoints$key,
}

export default function ArticleKeypoint({ article }: Props) {
  const data = useFragment(fragment, article);

  return (
    <div className='article-keypoints-section'>
      <div className='section-header'>KEYPOINTS</div>
      <div className='article-keypoints-content'>
        {data.keypoints.map((keypoint: any, i: number) => (<div key={i}>{keypoint}</div>))}
      </div>
    </div>
  )
}