/**
 * @generated SignedSource<<d89c379ff53b2bf4846f74afd4049027>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryEnum = "ASIA" | "CHINA" | "CRYPTO" | "EARNINGS" | "ENERGY" | "ENTERTAINMENT" | "EUROPE" | "FINANCE" | "HEALTH_SCIENCE" | "LIFE" | "REAL_ESTATE" | "TECH" | "US" | "%future added value";
export type CategoryPageQuery$variables = {
  category: CategoryEnum;
};
export type CategoryPageQuery$data = {
  readonly category: {
    readonly id: string;
    readonly label: CategoryEnum;
    readonly " $fragmentSpreads": FragmentRefs<"CategoryPage_content">;
  } | null;
};
export type CategoryPageQuery = {
  response: CategoryPageQuery$data;
  variables: CategoryPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CategoryPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "category",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CategoryPage_content"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CategoryPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "category",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ArticlesConnection",
            "kind": "LinkedField",
            "name": "articles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArticleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "themeIndex",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isHidden",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "headline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBullish",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBearish",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "keypoints",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tags",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tickers",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "explanation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "articles(first:10)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CategoryPage_articles",
            "kind": "LinkedHandle",
            "name": "articles"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc97df0cb59e29249959e8a8c284306f",
    "id": null,
    "metadata": {},
    "name": "CategoryPageQuery",
    "operationKind": "query",
    "text": "query CategoryPageQuery(\n  $category: CategoryEnum!\n) {\n  category(category: $category) {\n    id\n    label\n    ...CategoryPage_content\n  }\n}\n\nfragment Article on Article {\n  id\n  body\n  themeIndex\n  isHidden\n  ...ArticleHeader\n  ...ArticleKeypoints\n  ...ArticleTags\n  ...ArticleExplanation\n  ...ArticleControlBar\n}\n\nfragment ArticleControlBar on Article {\n  id\n  isHidden\n}\n\nfragment ArticleExplanation on Article {\n  explanation\n  isBullish\n  isBearish\n}\n\nfragment ArticleHeader on Article {\n  id\n  headline\n  publishedAt\n  isBullish\n  isBearish\n  themeIndex\n}\n\nfragment ArticleKeypoints on Article {\n  keypoints\n}\n\nfragment ArticleTags on Article {\n  tags\n  tickers\n}\n\nfragment CategoryPage_content on Category {\n  articles(first: 10) {\n    edges {\n      node {\n        id\n        ...Article\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "7ee82190515ac55c8ce4047392a10842";

export default node;
