import React, { useEffect, useRef } from 'react';

import './LoadingArticle.css';


type Props = {
  pageSize: number,
  loadNext: (pageSize: number) => any,
  isLoading: boolean,
}


export default function LoadNextTrigger({ loadNext, isLoading, pageSize }: Props) { 
  
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isLoading) {
          loadNext(pageSize);
        }
      },
      { threshold: 1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, loadNext, isLoading, pageSize]);

  return (
    <div ref={observerTarget} className='animated-background' />
    
  );
}