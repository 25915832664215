type Props = {
  width: number,
}

export default function CopyIcon({width}: Props) {
  return (
    <svg width={width} viewBox="0 0 24 31" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.68359 7.12122H7.57031V4.34387C7.57031 3.18372 8.19141 2.51575 9.41016 2.51575H14.5898V8.52747C14.5898 9.88684 15.2461 10.5314 16.5938 10.5314H22.0781V20.5509C22.0781 21.7228 21.4453 22.379 20.2266 22.379H17.918V24.2657H20.332C22.7461 24.2657 23.9648 23.0235 23.9648 20.586V11.0704C23.9648 9.62903 23.6719 8.71497 22.793 7.81262L16.875 1.80091C16.043 0.945434 15.0586 0.629028 13.8047 0.629028H9.31641C6.90234 0.629028 5.68359 1.87122 5.68359 4.30872V7.12122ZM16.2891 8.24622V3.44153L21.5859 8.83215H16.8633C16.4531 8.83215 16.2891 8.65637 16.2891 8.24622ZM0 26.4337C0 28.8829 1.20703 30.1134 3.63281 30.1134H14.6484C17.0742 30.1134 18.2812 28.8712 18.2812 26.4337V17.2813C18.2812 15.7813 18.1055 15.1251 17.168 14.1642L10.7109 7.58997C9.82031 6.67591 9.08203 6.47669 7.76953 6.47669H3.63281C1.21875 6.47669 0 7.70715 0 10.1564V26.4337ZM1.88672 26.3985V10.1798C1.88672 9.03137 2.50781 8.36341 3.72656 8.36341H7.57031V15.1602C7.57031 16.6368 8.32031 17.3751 9.77344 17.3751H16.3945V26.3985C16.3945 27.5704 15.7617 28.2267 14.5547 28.2267H3.71484C2.50781 28.2267 1.88672 27.5704 1.88672 26.3985ZM9.99609 15.6056C9.52734 15.6056 9.33984 15.4181 9.33984 14.9493V8.797L16.0312 15.6056H9.99609Z" fill="currentColor"/>
    </svg>
  )
}