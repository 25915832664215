/**
 * @generated SignedSource<<f2677e62e8899551b2349d3f2ae2cc5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleControlBar$data = {
  readonly id: string;
  readonly isHidden: boolean;
  readonly " $fragmentType": "ArticleControlBar";
};
export type ArticleControlBar$key = {
  readonly " $data"?: ArticleControlBar$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleControlBar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleControlBar",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "0ceb33c3572c26d61cc842b10207da38";

export default node;
