import { getCurrentUrlParams } from '../../../routing/query_param_helper';
import Link from 'found/Link';
import React from 'react';

import './NavLink.css';


type Props = {
  label: string,
  path: string,
}

export default function NavLink({ label, path }: Props) {
  return (
    <div className="navLink-wrapper">
      <Link 
        className='navLink-link'
        to={{
          pathname: path,
          query: getCurrentUrlParams(),
        }}
        activeClassName='navLink-link-active'
        exact={true}>
        {label}
      </Link>
    </div>
  )
}